import React from 'react';
import Menu from '../common/menu';
import Footer from '../common/footer';
import HeroServiceAreas from './hero-service-areas';
import ContentServiceAreas from './content-service-ares';


function MainServiceAreas() {
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroServiceAreas />
            <ContentServiceAreas />
            <Footer />
        </div>
    );
}

export default MainServiceAreas; 