import React from 'react';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import HeroGoldCoast from './hero-goldcoast';
import ContentGoldCoast from './content-goldcoast';


function MainGoldCoast() {
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroGoldCoast />
            <ContentGoldCoast />
            <Footer />
        </div>
    );
}

export default MainGoldCoast; 