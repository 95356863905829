import React from 'react';

function ContentSunshineState() {

    return (
        <section className="wrapper bg-light">
            <div className="container pt-4 pb-8">
                <div className="row gy-5 gx-5 align-items-center">
                    <p className="mb-1">
                        <b className="fs-18">Welcome to Business Class Chauffeur, Sunshine Coast’s Leading Limousine Service</b><br />
                        Experience unparalleled luxurious and comfort with our pinnacle class services in Sunshine Coast. Whether you are traveling for business or leisure, we make sure each journey is snug, fashionable, and hassle-free.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">Limousine Service Sunshine Coast</h2>
                    <p>
                        <b className="fs-18">Elevate Your Travel Experience - </b>
                        Our limousine provider in Sunshine Coast offers the remaining in sophistication and luxury. Whether you're attending a unique occasion or want high priced transportation, our fleet is designed to cater for your every want.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">Sunshine Coast Airport Transfers</h2>
                    <p className="mb-1">
                        <b className="fs-18">Seamless and Luxurious Transfers - </b>
                        Say goodbye to airport journey stress with our Sunshine Coast airport transfers. Enjoy spark off and stylish transportation, ensuring you arrive or leave in fashion.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Chauffeur Service Sunshine Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Professional and Reliable - </b>
                        Experience the difference of our chauffeur service in Sunshine Coast. Our professional chauffeurs offer a clean and steady trip, perfect for company journeys, specific occasions, or day by day commutes.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Private Car Hire Sunshine Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Tailored for Your Comfort - </b>
                        Discover the liberty and luxury of private automobile rent in Sunshine Coast. Our immoderate-stop automobiles and personalized provider make certain an unforgettable tour revel in.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Wedding Car Hire Sunshine Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Make Your Big Day Special - </b>
                        Create loved reminiscences with our bridal ceremony vehicle rent in Sunshine Coast. Our luxurious automobiles and professional chauffeurs upload splendor and class in your particular day.
                        <br />
                        Please call us in this huge variety for inquiries or reserving: <b><a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a></b>
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Corporate Car Service Sunshine Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Business Travel Redefined - </b>
                        Impress your customers and beautify your corporation picture with our organisation vehicle issuer in Sunshine Coast. Punctuality, professionalism, and comfort are confident.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Event Transportation Sunshine Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Your Partner for Memorable Events - </b>
                        From live shows to meetings, our occasion transportation services in Sunshine Coast ensure smooth and fashionable journey for all your important sports.
                        <br />
                        Please call us in this variety for inquiries or reserving: <b><a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a></b>
                    </p>


                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Sunshine Coast Luxury Rides</h2>
                    <p className="mb-2">
                        <b className="fs-18">Travel in Style - </b>
                        Experience the pleasant Sunshine Coast has to provide with our luxury rides. Perfect for any event, our fleet combines consolation, beauty, and sophistication to exceed your expectations.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Why Choose Us?</h2>
                    <b className="fs-18">The Benefits of Traveling with Us - </b>
                    <ul className="icon-list bullet-bg bullet-soft-yellow">
                        <li><i className="uil uil-check"></i>Luxury Fleet: A big choice of top-tier vehicles for each occasion.</li>
                        <li><i className="uil uil-check"></i>Professional Chauffeurs: Experienced and courteous drivers for a clean journeyy.</li>
                        <li><i className="uil uil-check"></i>Timely Service: We fee it slow and make sure punctuality on every occasion.</li>
                        <li><i className="uil uil-check"></i>Competitive Pricing: Premium services at low-priced costs.</li>
                    </ul>

                    <p className="mb-2">
                    Book your adventure in recent times and allow us to redefine the way you travel in Sunshine Coast. Experience the right combination of luxury, consolation, and reliability with our offerings tailored best for you.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default ContentSunshineState;