import React from 'react';
import { Link } from 'react-router-dom';
import { BookAndManageButton } from '../common/genericComponent';


function HeroServiceAreas() {
    return (
        <section className="wrapper image-wrapper bg-image bg-overlay text-white" data-image-src="./assets/img/photos/b16.jpg">
            <div className="container py-12 position-relative">
                <div className="row gx-0 align-items-center">
                    <div className="col-12 col-md-6 text-start" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                        <div className="py-1 py-md-2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item"><Link to="/service-areas">Service Areas</Link></li>
                                </ol>
                            </nav>
                        </div>
                        <h1 className="display-2 mb-4 mx-sm-n2 mx-md-0 text-white">Service Areas We Operate In</h1>
                        <p className="fs-15 mb-4">Prestige, luxury and well-appointed vehicles to keep you moving in
                            comfort and style.</p>
                        <div className="d-flex justify-content-start mb-5 gap-2 flex-column flex-md-row" data-cues="slideInDown" data-delay={process.env.REACT_APP_DELAY}>
                            <BookAndManageButton />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HeroServiceAreas;