import React from 'react';
import { Link } from 'react-router-dom';

function ContentServiceAreas() {

    return (
        <section className="wrapper bg-light">
            <div className="container pt-4 pb-8">
                <div className="row gy-5 gx-5 align-items-center">
                    <p className="mb-1">
                        <b className="fs-18">Service Areas We Operate In</b><br />
                        At Business Class Chauffeur, we take pride in offering exceptional luxury transportation services across key locations. Whether you are traveling for business, leisure, or a special occasion, our professional chauffeurs and premium vehicles are here to ensure a seamless journey in the following areas:
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">1. <Link to="/service-area-brisbane">Brisbane</Link></h2>
                    <p className="mb-1">
                        Discover the ultimate in luxury travel with our services in Brisbane. From airport transfers to corporate transportation and special events, our Brisbane offerings are designed to meet your every need with elegance and efficiency.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">2. <Link to="/service-area-goldcoast">Gold Coast</Link></h2>
                    <p className="mb-1">
                        Experience the glamour of the Gold Coast with our top-tier chauffeur services. Whether it’s a day at the beach, a corporate event, or a wedding, our tailored services ensure a sophisticated and stress-free experience.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">3. <Link to="/service-area-sunshinestate">Sunshine Coast</Link></h2>
                    <p className="mb-1">
                        Enjoy the beauty of the Sunshine Coast with our premium limousine services. Perfect for exploring this scenic region or making a grand entrance at your event, we guarantee comfort and style in every journey.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Why Choose Business Class Chauffeur?</h2>
                    <ul className="icon-list bullet-bg bullet-soft-yellow">
                        <li><i className="uil uil-check"></i>Luxury Fleet: A wide selection of top-tier vehicles for every occasion.</li>
                        <li><i className="uil uil-check"></i>Professional Chauffeurs: Experienced and courteous drivers for a smooth journey.</li>
                        <li><i className="uil uil-check"></i>Timely Service: We value your time and ensure punctuality every time.</li>
                        <li><i className="uil uil-check"></i>Customized Packages: Tailored services for all occasions.</li>
                    </ul>

                    <p className="mb-2">
                        Explore our service areas and book your ride today to experience the best in luxury transportation. With Business Class Chauffeur, every journey becomes extraordinary!
                    </p>
                </div>
            </div>
        </section>

    );
}

export default ContentServiceAreas;