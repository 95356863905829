import { React, useEffect } from 'react';
import Menu from '../../common/menu';
import HeroBrisbane from './hero-brisbane';
import Footer from '../../common/footer';
import ContentBrisbane from './content-brisbane';


function MainBrisbane() {
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroBrisbane />
            <ContentBrisbane />
            <Footer />
        </div>
    );
}

export default MainBrisbane; 