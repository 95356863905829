import React from 'react';
import Menu from '../../common/menu';
import Footer from '../../common/footer';
import HeroSunshineState from './hero-sunshinestate';
import ContentSunshineState from './content-sunshinestate';


function MainSunshineState() {
    return (
        <div className="content-wrapper">
            <Menu />
            <HeroSunshineState />
            <ContentSunshineState />
            <Footer />
        </div>
    );
}

export default MainSunshineState; 