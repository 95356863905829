import React from 'react';

function ContentBrisbane() {

    return (
        <section className="wrapper bg-light">
            <div className="container pt-4 pb-8">
                <div className="row gy-5 gx-5 align-items-center">
                    <p className="mb-1">
                        <b className="fs-18">Welcome to Business Class Chauffeur, Brisbane’s Leading Limousine Service </b><br />
                        Experience the pinnacle of luxury and comfort with our limousine service Brisbane, tailored to meet all of your transportation desires. Whether you’re arriving on the Brisbane Airport or planning a grand occasion in the city, our top splendor offerings make sure a persevering with and complex adventure.
                    </p>
                    <b className="fs-18 text-uppercase text-gradient gradient-1 mb-2">Limousine Service Brisbane</b>
                    <p >
                        <b className="fs-18">Experience Unmatched Comfort and Elegance - </b> Welcome to the epitome of steeply-priced and class with our top class limousine enterprise in Brisbane. Whether you’re journeying for enterprise organization or pleasure, our pinnacle-tier offerings are tailored to provide a continuing and elegant transportation revel in.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">Brisbane Airport Transfers</h2>
                    <p className="mb-1">
                        <b className="fs-18">Stress-Free, Luxurious Arrivals and Departures - </b>
                        Forget the problem of airport transportation with our incredible Brisbane airport transfers. Whether you are catching a flight or arriving in the town, our chauffeurs make certain nicely timed and snug tour.
                    </p>


                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Luxury Car Hire Brisbane</h2>
                    <p className="mb-2">
                        <b className="fs-18">Redefining Travel Standards - </b>
                        Indulge in the pricey you deserve with our luxurious vehicle lease in Brisbane. From corporation activities to weddings, we offer an intensive fleet of excessive-give up automobiles to cater in your every want.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Corporate Limousine Brisbane</h2>
                    <p className="mb-2">
                        <b className="fs-18">Professionalism at Its Finest - </b>
                        Make your employer journeys problem-free and unexpected with our organization limousine offerings in Brisbane. Arrive at meetings, conferences, or business organization business enterprise sports activities in a car that reflects your professional stature.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Premium Chauffeur Service Brisbane</h2>
                    <p className="mb-2">
                        <b className="fs-18">Your Trusted Travel Companion - </b>
                        Experience extremely good organization with our pinnacle elegance chauffeur business enterprise in Brisbane. Whether you want a reliable enjoy for an evening out, a completely unique occasion, or each day commutes, our professional chauffeurs are proper right here to make your adventure first-rate.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Wedding Limousine Brisbane</h2>
                    <p className="mb-2">
                        <b className="fs-18">Wedding Car Hire Brisbane - </b>
                        Make your unique day unforgettable with our bridal ceremony limousine Brisbane offerings. Arrive in beauty and fashion, growing reminiscences to cherish all of the time. Our highly-priced limousines are best for the bride and groom, bridal party, or family, along with a touch of class to your marriage ceremony day. Our organization works cautiously with you to ensure each detail is outstanding.
                        <br />
                        Please name us on this quantity for the inquiries or reserving: <b><a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a></b>
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Why Choose Us?</h2>
                    <b className="fs-18">The Benefits of Traveling with Us - </b>
                    <ul className="icon-list bullet-bg bullet-soft-yellow">
                        <li><i className="uil uil-check"></i>Luxury Fleet: A massive desire of top-tier vehicles for every occasion.</li>
                        <li><i className="uil uil-check"></i>Professional Chauffeurs: Experienced and courteous drivers for an easy journey.</li>
                        <li><i className="uil uil-check"></i>Timely Service: We charge it slow and ensure punctuality on every occasion.</li>
                        <li><i className="uil uil-check"></i>Competitive Pricing: Premium services at low-fee charges.</li>
                    </ul>

                    <p className="mb-2">
                        Book your revel in nowadays and permit us to redefine the way you adventure in Brisbane. Experience the great mixture of high-priced, comfort, and reliability with our services tailored great for you.
                    </p>
                </div>
            </div>
        </section>

    );
}

export default ContentBrisbane;