import React from 'react';

function ContentGoldCoast() {

    return (
        <section className="wrapper bg-light">
            <div className="container pt-4 pb-8">
                <div className="row gy-5 gx-5 align-items-center">
                    <p className="mb-1">
                        <b className="fs-18">Welcome to Business Class Chauffeur, Gold Coast’s Premier Limousine Service </b><br />
                        Discover the last in luxurious and convenience with our limousine service in Gold Coast. Whether you’re arriving at Gold Coast Airport or planning a grand event, our top class offerings make certain a unbroken and sophisticated adventure.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">Limousine Service Gold Coast</h2>
                    <p>
                        <b className="fs-18">Experience Unmatched Comfort and Elegance - </b> Welcome to the top of luxury and class with our top rate limousine provider in Gold Coast. Whether you're journeying for commercial enterprise or pleasure, our top-tier services are tailored to offer a continuing and fashionable transportation enjoy.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-2">Gold Coast Airport Transfers</h2>
                    <p className="mb-1">
                        <b className="fs-18">Stress-Free, Luxurious Arrivals and Departures - </b>
                        Forget the hassle of airport transportation with our special Gold Coast airport transfers. Whether you are catching a flight or arriving within the town, our chauffeurs make sure timely and snug tour.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Luxury Car Hire Gold Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Redefining Travel Standards - </b>
                        Indulge within the luxurious you deserve with our luxurious vehicle lease in Gold Coast. From corporate events to weddings, we provide an extensive fleet of excessive-stop vehicles to cater for your each want.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Gold Coast Chauffeur Service</h2>
                    <p className="mb-2">
                        <b className="fs-18">Your Trusted Travel Companion - </b>
                        Experience remarkable service with our top rate chauffeur provider in Gold Coast. Whether you want a dependable journey for a night out, a unique occasion, or each day commutes, our professional chauffeurs are right here to make your journey remarkable.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Private Airport Transfers Gold Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Exclusive and Timely Transfers - </b>
                        Our personal airport transfers in Gold Coast are designed to make your tour experience pressure-free. Enjoy the privacy and luxury of a chauffeured vehicle tailored for your agenda.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Corporate Car Hire Gold Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Professionalism at Its Finest - </b>
                        Elevate your business journeys with our company automobile rent services in Gold Coast. Arrive at meetings, meetings, or corporate occasions in a car that reflects your expert stature.
                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Wedding Limousine Gold Coast</h2>
                    <p className="mb-2">
                        <b className="fs-18">Make Your Special Day Unforgettable - </b>
                        Create undying memories with our wedding limousine offerings in Gold Coast. Our high priced limousines are best for the bride, groom, and bridal party, including a touch of class on your wedding day. Trust us to make every detail ideal.
                        <br />
                        Please name us in this quantity for inquiries or reserving: <b><a href={`tel:${process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}`} className="link-white">{process.env.REACT_APP_BUSINESS_SUPPORT_NUMBER}</a></b>

                    </p>

                    <h2 className="fs-18 text-uppercase text-gradient gradient-1 mb-3">Why Choose Us?</h2>
                    <b className="fs-18">The Benefits of Traveling with Us - </b>
                    <ul className="icon-list bullet-bg bullet-soft-yellow">
                        <li><i className="uil uil-check"></i>Luxury Fleet: A huge selection of pinnacle-tier vehicles for every event.</li>
                        <li><i className="uil uil-check"></i>Professional Chauffeurs: Experienced and courteous drivers for a smooth adventure.</li>
                        <li><i className="uil uil-check"></i>Timely Service: We fee a while and make certain punctuality each time.</li>
                        <li><i className="uil uil-check"></i>Competitive Pricing: Premium offerings at cheap prices.</li>
                    </ul>

                    <p className="mb-2">
                        Book your experience nowadays and let us redefine the way you journey in Gold Coast. Experience the correct combination of luxurious, consolation, and reliability with our offerings tailor-made only for you.
                    </p>
                </div>
            </div>
        </section>


    );
}

export default ContentGoldCoast;